import { GET_TITLE_SERVICES, RESET_TITLE_SERVICES } from "../constans/getTitle";

/**
 * It's a reducer that returns a string.
 * @param [state] - the current state of the reducer
 * @param action - {
 * @returns The state is being returned.
 */
export const getTitleReducer = (state = "", action) => {
    switch (action.type) {
        case GET_TITLE_SERVICES:
            return action.payload;
        case RESET_TITLE_SERVICES:
            return "";
        default:
            return state;
    }
};
