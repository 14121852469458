import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-scroll";

const Links = ({ lenguaje, section, num, name, title }) => {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "start" },
                    alignItems: { xs: "center", md: "end" },
                    flexDirection: "column",
                    height: { xs: "200px", md: "100%" },
                    width: { xs: "100%", md: "40%" },
                    color: "white",
                    paddingRight: { xs: "", md: "25px" },
                    background: "#11123D",
                }}
            >
                <Typography
                    variant="subtitle1"
                    fontWeight={"bold"}
                    mb={2}
                    sx={{
                        cursor: "pointer",
                    }}
                >
                    <Link
                        to={section[num]}
                        spy={true}
                        smooth={true}
                        duration={1000}
                    >
                        {lenguaje(`footer.${title}`)}
                    </Link>
                </Typography>

                {Object.values(
                    lenguaje(`footer.${name}`, { returnObjects: true })
                ).map((info) => (
                    <Typography
                        variant="code2"
                        fontWeight={"regular"}
                        my={1}
                        sx={{
                            textDecoration: "none",
                            color: "white",
                            fontSize: "14px",
                            "&:hover": {
                                cursor: "pointer",
                                color: `#4ea8a8`,
                            },
                            textAlign: { xs: "", md: "end" },
                        }}
                        key={info}
                    >
                        <Link
                            to={section[num]}
                            spy={true}
                            smooth={true}
                            duration={1000}
                        >
                            {info}
                        </Link>
                    </Typography>
                ))}
            </Box>
        </>
    );
};

export default Links;
