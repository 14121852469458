import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const Seccion = ({i, title, subtitle, images}) => {
    return (
        <>
            <Grid
                container
                spacing={2}
                my={5}
                justifyContent="center"
                alignItems="center"
                key={i}
            >
                {i % 2 === 0 ? (
                    <>
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            sx={{
                                textAlign: "center",
                            }}
                        >
                            <Box data-aos="fade-up">
                                <Typography
                                    sx={{
                                        fontSize: { xs: "26px" },
                                    }}
                                    fontWeight={"bold"}
                                    textAlign="center"
                                >
                                    {title}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    marginX: 10,
                                    marginTop: 5,
                                    fontSize: { xs: "22px" },
                                   
                                }}
                                data-aos="fade-up"
                            >
                                <Typography
                                    fontWeight={"regular"}
                                    sx={{
                                        fontSize: {
                                            xs: "14px",
                                            sm: "18px",
                                        },
                                    }}
                                    textAlign={{xs: 'center'}}
                                >
                                    {subtitle}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            sx={{
                                textAlign: { xs: "center", lg: "start" },
                                marginY: { xs: 10, md: 0 },
                            }}
                        >
                            <Box
                                data-aos="fade-down"
                                component="img"
                                src={images[i]}
                                alt={title}
                                sx={{
                                    height: { xs: 175, md: 300 },
                                }}
                            ></Box>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            sx={{ textAlign: "center" }}
                            order={{ xs: 2, lg: 1 }}
                        >
                            <Box
                                data-aos="fade-down"
                                component="img"
                                src={images[i]}
                                alt=""
                                sx={{
                                    height: { xs: 250, md: 400 },
                                }}
                            ></Box>
                        </Grid>
                        <Grid
                            item
                            order={{ xs: 1, lg: 2 }}
                            xs={12}
                            lg={5}
                            my={{ xs: 3, md: "" }}
                            sx={{
                                textAlign: "center",
                            }}
                        >
                            <Box data-aos="fade-up">
                                <Typography
                                    sx={{
                                        fontSize: { xs: "26px" },
                                    }}
                                    fontWeight={"bold"}
                                    textAlign="center"
                                >
                                    {title}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    marginX: 10,
                                    marginTop: 5,
                                    fontSize: { xs: "22px" },
                                }}
                                data-aos="fade-up"
                            >
                                <Typography
                                    fontWeight={"regular"}
                                    sx={{
                                        fontSize: {
                                            xs: "14px",
                                            sm: "18px",
                                        },
                                    }}
                                    textAlign={{xs: 'center'}}
                                >
                                    {subtitle}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

export default Seccion;
