import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Switch } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Flag from "react-world-flags";
import { Link } from "react-scroll";
import Logo from "../assets/img/logo.svg";
import { makeStyles } from "@mui/styles";

// import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
// import { Link as Enlace } from "@mui/material";

const useStyles = makeStyles({
    switch: {
        /* Changing the color of the switch. */

        //Local styles
        // "& .css-jsexje-MuiSwitch-thumb": {
        //     backgroundColor: "#dce775 !important",
        // },
        // "& .css-1yjjitx-MuiSwitch-track": {
        //     backgroundColor: "black !important",
        // },

        // "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
        //     {
        //         backgroundColor: "white !important",
        //     },
        // "& .css-19gndve": {
        //     backgroundColor: "#dce775 !important",
        // },
        // "& .css-19gndve": {
        //     backgroundColor: "white",
        // },
        // "& .css-19gndve.Mui-checked": {
        //     backgroundColor: "#dce775 !important",
        // },

        //Production styles
        "& .css-1nr2wod.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "white !important",
        },
        "& .MuiSwitch-switchBase.MuiSwitch-colorPrimary.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.PrivateSwitchBase-root.css-1nr2wod .css-19gndve": {
            backgroundColor: "white !important",
        },
        "& .MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.MuiButtonBase-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked.PrivateSwitchBase-root.Mui-checked.css-1nr2wod .css-19gndve": {
            backgroundColor: "#dce775 !important",
        }
    },
});

const Navbar = ({ section }) => {
    const classes = useStyles();

    /* A React Hook that allows you to use state in functional components. */
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [changeBG, setChangeBG] = useState(false);
    const [checked, setChecked] = useState(false);
    const [t, i18n] = useTranslation("global");

    const userLang = navigator.language.substring(0, 2);

    useEffect(() => {
        if (userLang === "es") {
            if (!checked) {
                i18n.changeLanguage("es");
            } else {
                i18n.changeLanguage("en");
            }
        } else {
            if (checked) {
                i18n.changeLanguage("es");
            } else {
                i18n.changeLanguage("en");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    /**
     * When the user clicks on the button, the menu will open.
     * @param event - The event that triggered the function.
     */
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    /**
     * When the user clicks on the menu button, the menu will open. When the user clicks on the menu
     * button again, the menu will close.
     */
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    /**
     * If the window is scrolled down 80px, change the background color. If the window is
     * scrolled up 80px, change the background color to transparent.
     */
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setChangeBG(true);
        } else {
            setChangeBG(false);
        }
    };

    const handleChange = (e) => {
        setChecked(e.target.checked);
    };

    /* Listening for the scroll event and calling the `changeBackground` function. */
    window.addEventListener("scroll", changeBackground);

    return (
        <>
            <AppBar
                sx={{
                    boxShadow: changeBG ? "" : "none",
                    background: changeBG
                        ? `linear-gradient(90deg, rgba(17,18,61,1) 0%, rgba(78,168,168,1) 100%)`
                        : "transparent",
                    paddingY: 1.5,
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar>
                        <Box
                            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
                        >
                            {/* A link to the first section of the page. */}
                            <Link
                                to={section[0]}
                                spy={true}
                                smooth={true}
                                duration={1000}
                            >
                                <img
                                    src={Logo}
                                    alt="LOGO-CGIANNS"
                                    height={55}
                                    className="imgCursor"
                                />
                            </Link>
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                            }}
                        >
                            {/* A button that when clicked, it opens the menu. */}
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            {/* A menu that is displayed when the user clicks on the menu button. */}
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: "block", md: "none" },
                                }}
                            >
                                {/* A loop that is iterating over the array of pages and creating a menu
                                item for each page. */}
                                {Object.values(
                                    t("navbar.pages", { returnObjects: true })
                                ).map((page) => (
                                    <MenuItem
                                        key={page}
                                        onClick={handleCloseNavMenu}
                                    >
                                        <Typography textAlign="center">
                                            <Link
                                                to={page}
                                                spy={true}
                                                smooth={true}
                                                duration={1000}
                                            >
                                                {page}
                                            </Link>
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <Box
                            sx={{
                                display: { xs: "flex", md: "none" },
                                flexGrow: 1,
                            }}
                        >
                            {/* A link to the first section of the page. */}
                            <Link
                                to={section[0]}
                                spy={true}
                                smooth={true}
                                duration={1000}
                            >
                                <img
                                    src={Logo}
                                    alt="LOGO-CGIANNS"
                                    height={55}
                                    className="imgCursor"
                                />
                            </Link>
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", md: "flex" },
                            }}
                        >
                            {/* Iterating over the array of pages and creating a menu item for each
                            page. */}
                            {Object.values(
                                t("navbar.pages", { returnObjects: true })
                            ).map((page) => (
                                <MenuItem
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign="center">
                                        <Link
                                            to={page}
                                            spy={true}
                                            smooth={true}
                                            duration={1000}
                                        >
                                            {page}
                                        </Link>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {/* A switch that when clicked, it changes the language of the page. */}
                            <Switch
                                checked={checked}
                                className={classes.switch}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "controlled" }}
                            />

                            {userLang === "es" ? (
                                <Flag code="gb" height="16" />
                            ) : (
                                <Flag code="es" height="16" />
                            )}

                            {/* <Enlace
                                href="insertar aqui el url"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    textDecoration: "none",
                                    color: "white",
                                    marginLeft: 3
                                }}
                            >
                                <ShoppingBagIcon />
                            </Enlace> */}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};
export default Navbar;
