import { Box, Typography } from "@mui/material";
import React from "react";
import CardServices from "./ui/CardServices";

const Services = ({ lenguaje, section }) => {
    return (
        <>
            {/* A component that is imported from the material-ui library. It is a component that is
                            used to display text. */}
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    color: "white",
                    background: `linear-gradient(90deg, rgba(23,97,161,1) 0%,  rgba(78,168,168,1) 50%, rgba(23,97,161,1) 100%)`,
                }}
                id={`${section[2]}`}
            >
                {/* A component that is imported from the material-ui library. It is a component that is
                used to display text. */}
                <Typography variant="h3" fontWeight={"bold"} mt={12}>
                    {lenguaje("services.title")}
                </Typography>

                {/* Mapping the values of the object and returning the title and subtitle. */}
                <Box
                sx={{
                    width: "100%",
                    my: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    color: "white",
                }}
                gap={5}
                id={`${section[2]}`}
            >
                {Object.values(
                    lenguaje("services.items", { returnObjects: true })
                ).map(({ title, subtitle, image }) => (
                    <CardServices
                        key={title}
                        title={title}
                        subtitle={subtitle}
                        section={section}
                        image={image}
                        info={lenguaje("services.info")}
                    />
                ))}

            </Box>
            </Box>
        </>
    );
};

export default Services;
