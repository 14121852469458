import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
} from "@mui/material";
import { Link } from "react-scroll";
import { getTitleServices } from "../../actions/getTitleAction";

const CardServices = ({ title, subtitle, section, image, info }) => {
    /* A state hook that is used to store the title of the card. */
    const [subject, setSubject] = useState("");

    /* A hook that is used to dispatch an action. */
    const dispatch = useDispatch();

    /* Dispatching the action getTitleServices with the subject as the payload. */
    useEffect(() => {
        dispatch(getTitleServices(subject));
    }, [subject, dispatch]);

    /**
     * When the user clicks on the button, the function will prevent the default action of the button,
     * and then set the subject to the title.
     * @param e - the event object
     */
    const handleClick = (e) => {
        e.preventDefault();
        setSubject(title);
    };

    return (
        <>
            <Card
                sx={{ maxWidth: 500, marginY: 0, width: { xs: 300, md: 500 } }}
                data-aos="zoom-in"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="3000"
            >
                <CardMedia
                    component="img"
                    alt={title}
                    sx={{
                        height: 200,
                        backgroundPosition: "center",
                        backgroundAttachment: "fixed",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: 'cover'
                    }}
                    image={image}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {subtitle}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">
                        <Link
                            to={section[3]}
                            spy={true}
                            smooth={true}
                            duration={1000}
                            onClick={handleClick}
                        >
                           {info}
                        </Link>
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

export default CardServices;
