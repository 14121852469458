import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Box, Button, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import SendIcon from "@mui/icons-material/Send";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import { removeTitle } from "../actions/getTitleAction";

const Contact = ({ lenguaje, section }) => {
    /* A hook that is used to get the state of the store. */
    let getTitle = useSelector((state) => state.getTitle);

    /* A hook that is used to dispatch an action to the store. */
    const dispatch = useDispatch();

    useEffect(() => {

    }, [getTitle])

    /* A function that returns an array of values from an object. */
    let data = Object.values(lenguaje("contact.data", { returnObjects: true }));
    let nameError = Object.values(
        lenguaje("formError.name", { returnObjects: true })
    );
    let emailError = Object.values(
        lenguaje("formError.email", { returnObjects: true })
    );
    let subjectError = Object.values(
        lenguaje("formError.subject", { returnObjects: true })
    );
    let infoError = Object.values(
        lenguaje("formError.info", { returnObjects: true })
    );

    const onClickDelete = () => {
        dispatch(removeTitle());
    };

    /* A validation schema for the form. */
    const validationSchema = yup.object({
        name: yup
            .string(`${nameError[0]}`)
            .required(`${nameError[1]}`)
            .min(3, `${nameError[2]}`)
            .max(15, `${nameError[3]}`),
        email: yup
            .string(`${emailError[0]}`)
            .email(`${emailError[1]}`)
            .required(`${emailError[2]}`),

        subject: yup
            .string(`${subjectError[0]}`)
            .required(`${subjectError[1]}`)
            .min(5, `${subjectError[2]}`)
            .max(50, `${subjectError[3]}`),
        message: yup
            .string(`${infoError[0]}`)
            .required(`${infoError[1]}`)
            .min(10, `${infoError[2]}`)
            .max(1000, `${infoError[3]}`),
    });

    /* A hook that is used to handle the form. */
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            subject: "",
            message: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            try {
                emailjs
                    .send(
                        process.env.REACT_APP_EMAIL_SERVICE_ID,
                        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
                        values,
                        process.env.REACT_APP_EMAIL_PUBLIC_KEY
                    )
                    .then((result) => {
                        toast.success(`${lenguaje("tooltips.success")}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        dispatch(removeTitle());
                        resetForm({
                            values: {
                                name: "",
                                email: "",
                                subject: "",
                                message: "",
                            },
                        });
                    });
            } catch (error) {
                toast.error(`${lenguaje("tooltips.error")}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
    });

    return (
        <Box
            sx={{
                width: "100%",
                paddingY: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
            id={`${section[3]}`}
        >
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />

            <Box
                sx={{
                    marginBottom: "22px",
                }}
                data-aos="fade-up"
                data-aos-delay="75"
                data-aos-duration="1500"
            >
                <Typography
                    sx={{
                        fontSize: { xs: "26px" },
                    }}
                    fontWeight={"bold"}
                >
                    {lenguaje("contact.title")}
                </Typography>
            </Box>

            {/* Form */}
            <form
                onSubmit={formik.handleSubmit}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <TextField
                    sx={{
                        width: { xs: "250px", md: "500px" },
                        marginY: 2,
                    }}
                    id="outlined-name"
                    name="name"
                    label={`${data[0]}`}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                    sx={{
                        width: { xs: "250px", md: "500px" },
                        marginY: 2,
                    }}
                    id="outlined-name"
                    name="email"
                    label={`${data[1]}`}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />

                {getTitle.length === 0 ? (
                    <TextField
                        sx={{
                            width: { xs: "250px", md: "500px" },
                            marginY: 2,
                        }}
                        id="outlined-name"
                        name="subject"
                        label={`${data[2]}`}
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.subject &&
                            Boolean(formik.errors.subject)
                        }
                        helperText={
                            formik.touched.subject && formik.errors.subject
                        }
                    />
                ) : (
                    <TextField
                        sx={{
                            width: { xs: "250px", md: "500px" },
                            marginY: 2,
                        }}
                        id="outlined-name"
                        name="subject"
                        label={`${data[2]}`}
                        value={(formik.values.subject = getTitle)}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.subject &&
                            Boolean(formik.errors.subject)
                        }
                        onClick={onClickDelete}
                        helperText={
                            formik.touched.subject && formik.errors.subject
                        }
                    />
                )}

                <TextField
                    sx={{
                        width: { xs: "250px", md: "500px" },
                        marginY: 2,
                    }}
                    id="outlined-multiline-static"
                    label={`${data[3]}`}
                    multiline
                    rows={5}
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                />

                <Button
                    variant="contained"
                    size="medium"
                    endIcon={<SendIcon />}
                    sx={{
                        backgroundColor: "#0A9EE4",
                        "&:hover": {
                            backgroundColor: "#57B868",
                        },
                    }}
                    type="submit"
                >
                    {lenguaje("contact.button")}
                </Button>
            </form>
        </Box>
    );
};

export default Contact;
