import React from "react";
import { Box, Typography } from "@mui/material";
import Seccion from "./ui/Seccion";

// Images
import startup2 from "../assets/img/startup2.svg";
import vision from "../assets/img/vision.svg";
import mision from "../assets/img/mision.svg";
// Our Values
import innovation from "../assets/img/innovation.svg";
import presentation from "../assets/img/presentation.svg";
import ranking from "../assets/img/ranking.svg";
import startup from "../assets/img/startup.svg";
import team from "../assets/img/team.svg";
import team2 from "../assets/img/team2.svg";
import team3 from "../assets/img/team3.svg";

const images = [startup2, mision, vision];
const ourValues = [
    startup,
    presentation,
    innovation,
    ranking,
    team,
    team2,
    team3,
];

const About = ({ lenguaje, section }) => {
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                marginY: 10,
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: 15,
            }}
            id={`${section[1]}`}
        >
            {/* Mapping the values of the object returned by the function lenguaje. */}
            {Object.values(
                lenguaje("about.content", { returnObjects: true })
            ).map(({ title, subtitle }, i) => (
                <Seccion
                    title={title}
                    subtitle={subtitle}
                    i={i}
                    images={images}
                    key={i}
                />
            ))}

            <Box
                sx={{
                    marginY: 10,
                    height: { xs: "80vh", lg: "100vh" },
                    width: "100%",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundImage:
                        "url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80)",
                }}
            >
                <Typography
                    color="white"
                    sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        fontWeight: "bold",
                        paddingX: 3,
                        fontSize: { xs: 35, sm: 45, md: 50, lg: 60 },
                    }}
                >
                    {lenguaje("quotes.tagline")}
                </Typography>
            </Box>

            <Box
                sx={{
                    maxWidth: "1440px",
                    marginY: 10,
                    textAlign: "center",
                }}
            >
                <Typography
                    sx={{ fontSize: { xs: "30px" } }}
                    fontWeight={"bold"}
                    textAlign="center"
                    data-aos="fade-up"
                >
                    {lenguaje("values.title")}
                </Typography>

                {Object.values(
                    lenguaje("values.content", { returnObjects: true })
                ).map(({ title, subtitle }, i) => (
                    <Seccion
                        title={title}
                        subtitle={subtitle}
                        i={i}
                        images={ourValues}
                        key={i}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default About;
