import { GET_TITLE_SERVICES, RESET_TITLE_SERVICES } from "../constans/getTitle";

/**
 * This function is a redux action creator that returns a function that takes a dispatch function as an
 * argument and dispatches an action with a type of GET_TITLE_SERVICES and a payload of the subject
 * argument.
 * @param subject - {
 */
export const getTitleServices = (subject) => (dispatch) => {
    dispatch({ type: GET_TITLE_SERVICES, payload: subject });
};

/**
 * When the user clicks the button, dispatch an action to the reducer to reset the title.
 */
export const removeTitle = () => (dispatch) => {
    dispatch({ type: RESET_TITLE_SERVICES})
}
