import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { getTitleReducer } from "./reducers/getTitleReducer";

/* Combining the reducers. */
const reducer = combineReducers({
    getTitle: getTitleReducer,
});

/* The initial state of the redux store. */
const initialState = {};

/* A middleware that allows us to use async actions. */
const middleware = [thunk];

/* Creating a store for the redux state. */
const store = createStore(
    reducer,
    initialState,
    applyMiddleware(...middleware)
);

export default store;
