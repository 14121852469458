import React from "react";
import Typewriter from "typewriter-effect";
import { Box, Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import online from "../assets/img/online.svg";

const HeroBanner = ({ lenguaje, section }) => {
    return (
        <Box id={`${section[0]}`}>
            {/* A component from the library @mui/material. It is a component that allows you to add styles
            to the component. */}
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: { xs: "100vh", sm: "110vh", md: "100vh" },
                    background: `linear-gradient(90deg, rgba(17,18,61,1) 0%, rgba(78,168,168,1) 100%)`,
                }}
            >
                <Grid
                    item={true}
                    xs={12}
                    md={6}
                    sx={{
                        textAlign: "center",
                        marginTop: { xs: 15, sm: 20, md: 0 },
                    }}
                >
                    <Typography
                        variant="h5"
                        color="white"
                        fontWeight="light"
                        data-aos="fade"
                        data-aos-duration="3000"
                    >
                        {lenguaje("hero-banner.welcome")}
                    </Typography>

                    <Typography fontSize={{ xs:'3.2rem', md: '6rem' }} fontWeight="bold" color="white">
                        {/* A component from the library "typewriter-effect" that allows you to create a
                        typewriter effect. */}
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .pauseFor(1000)
                                    .typeString("CGIANNS")
                                    .pauseFor(2500)
                                    .deleteChars(7)
                                    .typeString("TECHNOLOGY")
                                    .pauseFor(2500)
                                    .deleteChars(8)
                                    .start();
                            }}
                            options={{
                                loop: true,
                            }}
                            data-aos="fade"
                        />
                    </Typography>

                    <KeyboardArrowDownIcon
                        sx={{ color: "white", fontSize: "50px" }}
                        data-aos="fade"
                        data-aos-duration="1500"
                        className="mouse-wheel"
                    />
                </Grid>
                <Grid
                    item={true}
                    xs={12}
                    md={6}
                    sx={{
                        textAlign: {xs: "center", lg: 'start'},
                        // display: { xs: "none", md: "block" },
                        // overflow: "hidden",
                    }}
                >
                    <Box
                        component="img"
                        src={online}
                        alt="cgianns"
                        sx={{
                            height: { xs: 250, sm: 300, md: 350, lg: 450 },
                            marginBottom: { xs: 15, md: 0 },
                        }}
                        className="mouse-wheel2"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default HeroBanner;
