import React from "react";
import ReactDOM from "react-dom/client";
import AOS from "aos";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import { Provider } from "react-redux";
import store from "./store";

/* Initializing the AOS library. */
AOS.init();

// const spanish = ["es-ES", "es", "es-419", "es-VE", "es-MX", "es-US"];
// spanish.find((lenguage) => lenguage === navigator.language);

const userLang = navigator.language.substring(0, 2);

/* Initializing the i18next library. */
i18next.init({
    interpolation: { escapeValue: false },
    lng: userLang,
    fallbackLng: "en",
    resources: {
        es: {
            global: global_es,
        },
        en: {
            global: global_en,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <App />
        </Provider>
    </I18nextProvider>
);
