import React from "react";
import Logo from "../assets/img/logo.svg";
import { Box, Typography, Link as Enlace } from "@mui/material";
import { Link } from "react-scroll";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import Links from "./ui/Links";

const Footer = ({ lenguaje, section }) => {

    const currentYear = new Date().getFullYear();

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    background: "#11123D",
                    display: { xs: "block", md: "flex" },
                    justifyContent: "center",
                    alignItems: "center",
                    paddingY: 10,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                        width: { xs: "100%", md: "50%" },
                        background: "#11123D",
                    }}
                >
                    {/* A link to the top of the page. */}
                    <Link
                        to={section[0]}
                        spy={true}
                        smooth={true}
                        duration={1000}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        <img
                            src={Logo}
                            alt="logo-cgianns"
                            height={150}
                            className="imgCursor"
                        />
                    </Link>
                </Box>

                {/* A component that is being called. */}
                <Links
                    lenguaje={lenguaje}
                    section={section}
                    num={1}
                    title={"title-about"}
                    name={"about"}
                />
                {/* A component that is being called. */}
                <Links
                    lenguaje={lenguaje}
                    section={section}
                    num={2}
                    title={"title-services"}
                    name={"services"}
                />

                {/* Contact - Social Media */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: { xs: "center", md: "start" },
                        alignItems: { xs: "center", md: "end" },
                        flexDirection: "column",
                        height: { xs: "200px", md: "100%" },
                        width: { xs: "100%", md: "30%" },
                        color: "white",
                        paddingRight: { xs: "", md: "75px" },
                        background: "#11123D",
                    }}
                >
                    {/* A component that is being called. */}
                    <Typography
                        variant="subtitle1"
                        fontWeight={"bold"}
                        mb={2}
                        sx={{
                            cursor: "pointer",
                        }}
                    >
                        <Link
                            to={section[3]}
                            spy={true}
                            smooth={true}
                            duration={1000}
                        >
                            {lenguaje("footer.title-contact")}
                        </Link>
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                            marginBottom: 2,
                            cursor: "pointer",
                            transition: "ease-in",
                            "&:hover": {
                                fontSize: "18px",
                                color: `#4ea8a8`,
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <InstagramIcon height={20} />

                        <Typography
                            variant="code2"
                            fontWeight={"regular"}
                            ml={1}
                        >
                            <Enlace
                                href="https://www.instagram.com/cgiannstech/"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    textDecoration: "none",
                                    color: "white",
                                    "&:hover": {
                                        fontSize: "18px",
                                        color: `#4ea8a8`,
                                    },
                                }}
                            >
                                Instagram
                            </Enlace>
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                            marginBottom: 2,
                            cursor: "pointer",
                            transition: "ease-in",
                            "&:hover": {
                                fontSize: "18px",
                                color: `#4ea8a8`,
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <WhatsAppIcon height={20} />
                        <Typography
                            variant="code2"
                            fontWeight={"regular"}
                            ml={1}
                        >
                            <Enlace
                                href="https://wa.me/+584241487027"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    textDecoration: "none",
                                    color: "white",
                                    "&:hover": {
                                        fontSize: "18px",
                                        color: `#4ea8a8`,
                                    },
                                }}
                            >
                                WhastApp
                            </Enlace>
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "white",
                            marginBottom: 2,
                            cursor: "pointer",
                            transition: "ease-in",
                            "&:hover": {
                                fontSize: "18px",
                                color: `#4ea8a8`,
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <EmailIcon height={20} />
                        <Typography
                            variant="code2"
                            fontWeight={"regular"}
                            ml={1}
                        >
                            <Link
                                to={section[3]}
                                spy={true}
                                smooth={true}
                                duration={1000}
                            >
                                Email
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    backgroundColor: "#11123D",
                    paddingY: 4,
                }}
            >
                CGIANNS TECHNOLOGY &reg; {currentYear}
            </Box>
        </>
    );
};

export default Footer;
