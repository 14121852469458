import React from "react";
import { useTranslation } from "react-i18next";
import HeroBanner from "../components/HeroBanner";
import About from "../components/About";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Services from "../components/Services";
import ScrollToTop from "react-scroll-to-top";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const Layout = () => {
    /* A hook that allows you to use the i18next library. */
    const [t] = useTranslation("global");

    /* Getting the values of the object and returning them as an array. */
    let section = Object.values(t("navbar.pages", { returnObjects: true }));

    return (
        <>
            {/* Navbar */}
            <nav>
                <Navbar section={section}/>
            </nav>

            {/* Main */}
            <main>
                {/* <HeroBanner lenguaje={t} section={section} /> */}
                <HeroBanner lenguaje={t} section={section} />
                <About lenguaje={t} section={section} />
                <Services lenguaje={t} section={section} />
                <Contact lenguaje={t} section={section} />
            </main>

            <ScrollToTop smooth component={<ArrowUpwardIcon />} />
            
            <Footer lenguaje={t} section={section} />
        </>
    );
};

export default Layout;
